const routeNames = {
  home: 'home',
  restaurants: 'restaurants',
  restaurantsGroup: 'restaurantsGroup',
  city: 'city',
  menu: 'menu',
  shoppingCart: 'shoppingCart',
  checkout: 'checkout',
  orderSuccess: 'orderSuccess',
  userReport: 'userReport',
  cancelActiveOrder: 'cancelActiveOrder',
  info: 'info',
  sitemap: 'sitemap',
  signin: 'signin',
  updateUserDetails: 'updateUserDetails',
  linkMoneycard: 'linkMoneycard',
  coupons: 'coupons',
  accessibilityStatement: 'accessibilityStatement',
  otl: 'otl',
  payments: 'payments',
  errorPage: 'errorPage',
  reorder: 'reorder',
  deleteAccount: 'delete-account',
  chains: 'chains',
  orderSummary: 'order-summary',
  confirmAge: 'confirm-age',
  unsubscribe: 'unsubscribe',
  gfoOptOut: 'gfo-opt-out',
};

const InfoPagesNames = [
  'about-us',
  'add-company',
  'add-online-menu',
  'companies-advantages',
  'how-it-works',
  'online-menu',
  'privacy-policy',
  'restaurants-advantages',
  'report-a-bug',
  'recommend-a-restaurant',
  'terms-of-use',
  'deals-policy',
  'deals-policy-iec',
  'over-the-limit-terms',
];

const routes = [
  {
    name: routeNames.home,
    exact: true,
    path: ['/'],
  },
  {
    name: routeNames.restaurantsGroup,
    path: ['/restaurants/group/:groupId/:name?'],
  },
  {
    name: routeNames.restaurants,
    exact: true,
    path: [
      // accept legacy urls with deliveryMethod and addressKey
      '/restaurants/:search(search)?/:deliveryMethod?/:addressKey?',
    ],
  },
  {
    name: routeNames.city,
    path: ['/משלוחים/:cityNameSlug?/:cuisineNameSlug?', '/deliveries/:cityNameSlug?/:cuisineNameSlug?'],
  },
  {
    name: routeNames.menu,
    path: ['/restaurants/menu/delivery/:restaurantId/:restaurantSlug/dish/:dishId/:shoppingCartDishId?'],
  },
  {
    name: routeNames.menu,
    path: [
      // accept legacy urls with deliveryMethod
      '/restaurants/menu/:deliveryMethod/:restaurantId/:restaurantSlug?',
    ],
  },
  {
    name: routeNames.shoppingCart,
    path: ['/shopping-cart'],
    hideFooter: true,
  },
  {
    name: routeNames.checkout,
    path: ['/checkout'],
    hideFooter: true,
  },
  {
    name: routeNames.orderSuccess,
    path: ['/order-success/:orderId'],
  },
  {
    name: routeNames.orderSummary,
    path: ['/order-summary/:orderId'],
    hideFooter: true,
  },
  {
    name: routeNames.userReport,
    path: ['/user-report'],
  },
  {
    name: routeNames.cancelActiveOrder,
    path: ['/cancel-active-order'],
  },
  {
    name: routeNames.info,
    path: [`/:infoPageName(${InfoPagesNames.join('|')})`],
    hideFooter: true,
  },
  {
    name: routeNames.sitemap,
    path: ['/sitemap'],
  },
  {
    name: routeNames.signin,
    path: ['/signin'],
    hideFooter: true,
  },
  {
    name: routeNames.updateUserDetails,
    path: ['/update-user-details'],
    hideFooter: true,
    disableEruptModals: true,
  },
  {
    name: routeNames.linkMoneycard,
    path: ['/link-moneycard-request'],
    hideFooter: true,
    disableEruptModals: true,
  },
  {
    name: routeNames.coupons,
    path: ['/coupons/:restaurantId'],
    hideFooter: true,
  },
  {
    name: routeNames.accessibilityStatement,
    path: ['/accessibility-statement'],
  },
  {
    name: routeNames.otl,
    path: ['/otl'],
    hideFooter: true,
  },
  {
    name: routeNames.payments,
    path: ['/payments'],
    hideFooter: true,
  },
  {
    name: routeNames.reorder,
    path: ['/reorder'],
  },
  {
    name: routeNames.deleteAccount,
    path: ['/delete-account'],
    hideFooter: true,
  },
  {
    name: routeNames.chains,
    path: ['/chains'],
  },
  {
    name: routeNames.confirmAge,
    path: ['/confirm-age'],
    hideFooter: true,
  },
  {
    name: routeNames.unsubscribe,
    path: ['/unsubscribe/:unsubscribeToken'],
    hideFooter: true,
  },
  {
    name: routeNames.gfoOptOut,
    path: ['/gfo-opt-out'],
  },
  {
    name: routeNames.errorPage,
    path: ['/*'],
  },
];

module.exports = {
  routes,
  routeNames,
  InfoPagesNames,
};
